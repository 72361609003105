import platform from "./platform";

export const config = {
    appTitle: "Flexi Network",
    appSubTitle: "Flexi Network",
    appLogoUrl: 'https://s3.amazonaws.com/cdn.flexi.network/flexi.network/images/logo/logo.png',
    platform: platform
}

export default config;
