import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import {AppLayout} from "./layout/AppLayout.tsx";
import {PublicHomePage} from "./features/home/pages/PublicHomePage.tsx";
import {SignOutPage} from "./features/home/pages/SignOutPage.tsx";
import {RequireAuth} from "./utils/RequireAuth.tsx";
import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage.tsx";
import {Notifications} from "@mantine/notifications";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";
import {AboutPage} from "./features/home/pages/AboutPage.tsx";
import {ProductsPage} from "./features/home/pages/ProductsPage.tsx";
import {PricingPage} from "./features/home/pages/PricingPage.tsx";
import {AuthProvider} from "./context/AuthContext.tsx";


const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'about',
        element: <AboutPage/>
    }, {
        path: 'products',
        element: <ProductsPage/>
    }, {
        path: 'pricing',
        element: <PricingPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }]
}])

function App() {

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <UserAttributesContextProvider>
                    <Notifications/>
                    <RouterProvider router={router}/>
                </UserAttributesContextProvider>
            </AuthProvider>
        </MantineProvider>
    )
}

export default App
