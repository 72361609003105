import {useState} from 'react';
import {isAllowed} from "../services/access-control-client";


let testRole = ''

const testMode = false
// testRole = 'User'
// testRole = 'TenantAdmin'
if (testMode) {
    testRole = 'TenantAdmin'
}

export const useAccessControl = (role: string) => {

    const [userRoles, setUserRoles] = useState<string[]>([role]);

    if (testRole) {
        setUserRoles([testRole])
    }

    const evaluateAction = (action: string) => {

        if (typeof action === 'undefined') {
            throw new Error('action is required parameter');
        }

        if (Array.isArray(userRoles) && userRoles.length === 0) {
            throw new Error('user roles is required parameter');
        }

        try {
            return isAllowed(userRoles, action);
        } catch (e) {
            console.error('EXCEPTION IN EVALUATE ACTION: ', e);
            return false;
        }
    };

    return {
        userRoles,
        evaluateAction
    }

};
